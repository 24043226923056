* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
}

@font-face {
  font-family: "NexaSlab-Trial-Black";
  src: url(/src/fonts/NexaSlab-Trial-Black.ttf);
}

.fn-nexa {
  font-family: "Montserrat", sans-serif;
}
.fn-anonymous {
  font-family: "Anonymous Pro", monospace !important;
}

.font-eng {
  /* font-family: 'Genos', sans-serif; */
  font-family: "Montserrat", sans-serif;
}
.font-th {
  font-family: "Noto Sans Thai", sans-serif;
}
h1 {
  font-size: 28px;
}

.psolution {
  color: black;
  font-size: 18px;
}

.footerLink {
  color: black;
  text-decoration-line: none !important;
}

.bgMenu {
  /* background: rgb(29, 28, 61);
  background: linear-gradient(
    180deg,
    rgba(29, 28, 61, 1) 32%,
    rgba(10, 11, 15, 1) 74%,
    rgba(2, 2, 47, 1) 100%
  ); */
  background: #f3f2f2;
  color: #fff;
  font-weight: bold;
}

.bg {
  background-color: #f3f2f2;
  background-image: linear-gradient(10deg, #128acf71 8%, transparent 2%),
    linear-gradient(-10deg, #128acf71 6%, transparent 2%);
}

.bgfooter {
  background: rgb(29, 28, 61);
  background: linear-gradient(
    180deg,
    rgba(29, 28, 61, 1) 32%,
    rgba(10, 11, 15, 1) 74%,
    rgba(2, 2, 47, 1) 100%
  );
}

.btnMenu {
  border-radius: 10px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.btnMenu:active {
  color: #d30000 !important;
}
.menu-active > a {
  color: #d30000 !important;
}

.btnMenu :hover {
  color: #d30000 !important;
}

.pLogo {
  position: absolute;
  bottom: 10%;
  left: 53%;
}

.textOnPic {
  position: absolute;
  background-color: #fffffff3;
  width: 95%;
  bottom: 3%;
  padding: 20px;
}

.brightness {
  filter: brightness(85%);
}

.borderLeft {
  background-color: #ffffff00;
  border-left: 4px solid rgb(0, 0, 143);
  padding-inline: 10px;
}

.borderRight {
  background-color: #ffffff00;
  border-right: 4px solid rgb(0, 0, 143);
  padding-inline: 10px;
}

.bgContentLeft {
  background-color: #ffffff;
  box-shadow: 20px 20px rgba(255, 255, 255, 0.329);
}
.bgContentRight {
  background-color: #ffffff;
  box-shadow: -20px 20px #ffffff54;
}

.bgContent {
  background-color: rgb(255, 255, 255);
}

h1 {
  font-weight: bold;
}

.btn1 {
  background-color: #0066ff5e;
  padding-block: 4px;
  padding-inline: 15px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
}

.textOnPicLeft {
  position: absolute;
  background-color: #fffffff3;
  width: 30%;
  bottom: 10px;
  left: 10px;
  padding: 0px;
  text-align: center;
}

.font-response-h1 {
  font-size: clamp(0.1rem, -1rem + 3.5vw, 3.5rem);
}

.font-response-p {
  font-size: clamp(0.9rem, -0.8rem + 1.8vw, 18px);
}

p {
  /* font-size: clamp(0.8rem, -0.875rem + 5vw, 1.2rem); */
  font-size: 16px;
}

/* .slider {
  background-color: #ffffff;
  box-shadow: 0 0 30px #5b87ff96;
} */
.phoneNumber {
  padding: "5px";
}

/* .bgimage {
  background-image: url("../public/images/home/DevOps.png");
  height: 330px;
  width: 250px;
  background-position: center;
  background-color: rgba(0, 0, 255, 0.137);
  background-repeat: no-repeat;
} */

.textpositionhome {
  position: absolute;
  margin: auto;
  background-color: #ffffffc9;
  height: 40px;
  width: 100%;
  width: 280px;
  bottom: 0px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #474646;
  text-decoration-line: none;
  border-radius: 0px 0px 12px 12px;
}
.borderhead {
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgb(120, 50, 248),
      rgba(255, 255, 255, 0)
    )
    1;
}
.borderheadreverse {
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgb(120, 50, 248)
    )
    1;
}

.image-container {
  position: relative;
  width: 250px;
  height: 330px;
  border: 3px solid #e9e9e9;
}

.image-container .image-element {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picRotateL {
  transform: rotate(350deg) scale(1);
}
.picRotateR {
  transform: rotate(10deg) scale(1);
}

.home-border {
  background: rgb(243, 242, 242);
  background: linear-gradient(
    90deg,
    rgba(243, 242, 242, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(254, 254, 254, 1) 50%,
    rgba(243, 242, 242, 0) 100%
  );
}

.home-border2 {
  background: rgb(243, 242, 242);
  background: linear-gradient(
    90deg,
    rgba(243, 242, 242, 0) 0%,
    rgba(0, 0, 0, 0.582) 40%,
    rgba(0, 0, 0, 0.582) 50%,
    rgba(0, 0, 0, 0.582) 60%,
    rgba(243, 242, 242, 0) 100%
  );
}

.home-shadowFont {
  font-weight: 900;
  color: rgb(0, 0, 0);
  text-shadow: -1px 0 rgb(255, 255, 255), 0 1px rgb(255, 255, 255),
    1px 0 rgb(255, 255, 255), 0 -1px rgb(255, 255, 255);
}

.solution-link {
  color: rgb(255, 0, 0);
  font-size: 16px;
  text-decoration: none;
}

.home-link {
  color: white;
  font-size: 16px;
  text-decoration: none;
}

#introduce a:hover {
  color: rgb(255, 0, 0);
}
.home-link2--container a:hover {
  color: rgb(255, 0, 0);
}

/* 
.home-link :hover {
  color: rgb(255, 0, 0);
} */

.home-link2 {
  color: black;
  text-decoration: none;
}

.carousel-asseenon-container {
  padding-block: 100px;
}

@media only screen and (min-width: 1200px) {
  .flex-end {
    display: flex;
    justify-content: flex-end !important;
    text-align: center !important;
  }
  .flex-end2 {
    display: flex;
    justify-content: flex-end !important;
  }

  .flex-start {
    display: flex;
    justify-content: flex-start !important;
    text-align: center !important;
  }
  .flex-start2 {
    display: flex;
    justify-content: flex-start !important;
  }

  .textend {
    text-align: end !important;
  }

  .textstart {
    text-align: start !important;
  }
  .text-res-center {
    text-align: center;
  }
  .home-banner {
    position: absolute;
    /* width: 55%; */
    /* top: 45%; */
    bottom: 10px;
    /* height: 100%; */
    /* right: 2%; */
    left: 42%;
  }
  .home-res-center-text {
    position: absolute !important;
    text-align: center;
    margin: auto;
    right: 50%;
  }
  .home-res-center-text-2 {
    position: absolute !important;
    text-align: center;
    margin: auto;
  }
  .home-pic {
    text-align: start !important ;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Asap", sans-serif;
}
body {
  background: #42455a;
}
section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
section:nth-child(1) {
  color: #e0ffff;
}
section:nth-child(2) {
  color: #42455a;
  background: #e0ffff;
}
section:nth-child(3) {
  color: #e0ffff;
}
section:nth-child(4) {
  color: #42455a;
  background: #e0ffff;
}
section .container {
  margin: 100px;
}
section h1 {
  font-size: 3rem;
  margin: 20px;
}
section h2 {
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  section h1 {
    font-size: 2rem;
    text-align: center;
  }
  section .text-container {
    flex-direction: column;
  }
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-top {
  animation: fade-top 1s ease-in;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
.active.gelatine {
  animation: gelatine 0.5s ease-in;
}

.move-text {
  animation: gelatine 1s ease-in;
}
/* .active.slide-bck-center {
  animation: slide-bck-center 1s ease-in;
} */

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-top {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }

  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}
.css-tc4fgt-MuiAutocomplete-root {
  font-size: 14px !important;
}
